export default {
  "Министерство просвещения Республики Абхазия":
    "Министерство просвещения Республики Абхазия",
  Документы: "Документы",
  "Кабинетом Министров Республики Абхазия":
    "Кабинетом Министров Республики Абхазия по представлению Министра утверждается Коллегия Министерства, которая является совещательным органом, рассматривающим на своих заседаниях основные вопросы, отнесенные к компетенции Министерства. Решения Коллегии принимаются большинством голосов, оформляются протоколами и реализуются приказами Министра.",
  "В состав Коллегии входят":
    "В состав Коллегии входят: Министр (председатель Коллегии), заместители Министра, руководители структурных подразделений Министерства, а также руководители подведомственных организаций. По согласованию с иными органами государственного управления и организациями, в том числе общественными, в состав Коллегии могут входить их представители, а также иные лица. Персональный состав Коллегии утверждает Кабинет Министров Республики Абхазия.",
  "Министерство просвещения Республики Абхазия (далее - Министерство) является центральным органом":
    "Министерство просвещения Республики Абхазия (далее - Министерство) является центральным органом государственного управления, осуществляющим функции по реализации государственной политики и нормативно- правовое регулирование в области образования, государственного языка Республики Абхазия и наименований географических объектов. (в ред.Постановления Кабинета Министров Республики Абхазия от 30.06.2021 N 67)",
  "Министерство в своей деятельности руководствуется Конституцией Республики Абхазия":
    "Министерство в своей деятельности руководствуется Конституцией Республики Абхазия, конституционными законами Республики Абхазия, законами Республик Абхазия, актами Президента Республики Абхазия и Кабинета Министров Республики Абхазия, международными договорами Республики Абхазия и настоящим Положением.",
  "Положение О министерстве": "Положение О министерстве",
  ФИО: "ФИО",
  "Напишите заявление по": "Напишите заявление по",
  образцу: "образцу",
  Отправить: "Отправить",
  "и прикрепите фотографию или отсканированную копию":
    "и прикрепите фотографию или отсканированную копию",
  "Прикрепить заявление": "Прикрепить заявление",
  "Свяжитесь с нами": "Свяжитесь с нами",
  Документы: "Документы",
  "Посмотреть все документы": "Посмотреть все документы",
  "Посмотреть все анонсы": "Посмотреть все анонсы",
  "Анонсы ближайших событий": "Анонсы ближайших событий",
  "О министерстве": "О министерстве",
  Руководство: "Руководство",
  "Подведомственные организации": "Подведомственные организации",
  Реквизиты: "Реквизиты",
  "Коллегия": "Коллегия",
  Деятельность: "Деятельность",
  Статистика: "Статистика",
  "Конкурсы и олимпиады": "Конкурсы и олимпиады",
  "Пресс-центр": "Пресс-центр",
  Аккредитация: "Аккредитация",
  Медиатека: "Медиатека",
  Новости: "Новости",
  Документы: "Документы",
  "Трудовой кодекс": "Трудовой кодекс",
  "Нормативно-правовые акты": "Нормативно-правовые акты",
  "План мероприятий": "План мероприятий",
  Контакты: "Контакты",
  "Электронные книги": "Электронные книги",
  "Обращения граждан": "Обращения граждан",
  "Опросы и тестирование": "Опросы и тестирование",
  "Форма связи для личного приема граждан":
    "Форма связи для личного приема граждан",
  "Часто задаваемые вопросы": "Часто задаваемые вопросы",
  "Отчет о работе с обращениями граждан":
    "Отчет о работе с обращениями граждан",
  Заявления: "Заявления",
  "Министерство просвещения и": "Министерство просвещения и",
  "языковой политики Республики Абхазия":
    "языковой политики Республики Абхазия",
  "Разработка ASAR Studio": "Разработка ASAR Studio",
  ФИО: "ФИО",
  Сообщение: "Сообщение",
  Отправить: "Отправить",
  Деятельность: "Деятельность",
  "Официальный сайт Министерства просвещения Республики Абхазия":
    "Официальный сайт Министерства просвещения Республики Абхазия",
  "Центральный орган государственного управления":
    "Центральный орган государственного управления, осуществляющий функции по реализации государственной политики и нормативно-правовое регулирование в области образования, государственного языка Республики Абхазия и наименований географических объектов",
  Медиатека: "Медиатека",
  "Посмотреть все": "Посмотреть все",
  Новости: "Новости",
  "Посмотреть все новости": "Посмотреть все новости",
  "Поиск по сайту": "Поиск по сайту",
  "Социальные сети": "Социальные сети",
  "Номер телефона": "Номер телефона",
  Кабинет: "Кабинет",
  "Государственные ресурсы": "Государственные ресурсы",
  "Президент Республики Абхазия": "Президент Республики Абхазия",
  "Народное собрание": "Народное собрание",
  "Кабинет министров Республики Абхазия":
    "Кабинет министров Республики Абхазия",
  Директор: "Директор",
  Адрес: "Адрес",
  Телефон: "Телефон",
  "Юридический адрес": "Юридический адрес",
  "г.Сухум, ул. Званба, 9": "г.Сухум, ул. Званба, 9",
  ИНН: "ИНН",
  КПП: "КПП",
  ОГРН: "ОГРН",
  "Расчетный счет": "Расчетный счет",
  "Специальный счет": "Специальный счет",
  КБК: "КБК",
  "Специальный счет": "Специальный счет",
  "Л/С (бюджет)": "Л/С (бюджет)",
  "Л/С (спецс)": "Л/С (спецс)",
  "Спец/сч (л/сч)": "Спец/сч (л/сч)",
  Банк: "Банк",
  "Национальный Банк РА": "Национальный Банк РА",
  БИК: "БИК",
  "Все книги": "Все книги",
  "Выберите урок": "Выберите урок",
  "Отправить заявку": "Отправить заявку",
  Видео: "Видео",
  Фото: "Фото",
  Новости: "Новости",
  Медиатека: "Медиатека",
  "Эта новость в Медиатеке": "Эта новость в Медиатеке",
  "Посмотреть все медиа": "Посмотреть все медиа",
  "Новости и анонсы": "Новости и анонсы",
  "Сортировка по тегам": "Сортировка по тегам",
  Главная: "Главная",
  "Отправить письмо": "Отправить письмо",
  "Коллегия": "Коллегия",
  "О Министерстве": "О Министерстве",
  "Отправить заявление": "Отправить заявление",
  "Напишите заявление по образцу и прикрепите фотографию или отсканированную копию":
    "Напишите заявление по образцу и прикрепите фотографию или отсканированную копию",
  Заявления: "Заявления",
};
