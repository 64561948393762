<script setup lang="ts">
import { useAsyncData, useCookie } from "#app";
import { useI18n } from "vue-i18n";
import { COOKIE_KEYS } from "./utils/constants";
import "./assets/css/tailwind.css";

const savedLocale = useCookie(COOKIE_KEYS.i18n);

const { locale } = useI18n({
  useScope: "global",
});

if (savedLocale.value) locale.value = savedLocale.value;
</script>

<template>
  <NuxtPage />
</template>
