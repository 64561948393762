import { default as commissionHoTcyQTpjHMeta } from "/app/pages/about/commission.vue?macro=true";
import { default as ministry_45powersDNZsGFqa3JMeta } from "/app/pages/about/ministry-powers.vue?macro=true";
import { default as _91id_93hm8OeeTuUiMeta } from "/app/pages/about/organizations/[id].vue?macro=true";
import { default as indexXDBRioRxDcMeta } from "/app/pages/about/organizations/index.vue?macro=true";
import { default as requisitesq0CXdNlnEyMeta } from "/app/pages/about/requisites.vue?macro=true";
import { default as _91id_9307jRmXJISLMeta } from "/app/pages/about/staff/[id].vue?macro=true";
import { default as indexaV9c05q2UkMeta } from "/app/pages/about/staff/index.vue?macro=true";
import { default as claimJbNQuedtVVMeta } from "/app/pages/appeals/claim.vue?macro=true";
import { default as _91name_93FrnaUNS3NgMeta } from "/app/pages/books/[name].vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as accreditationJ7elZdgoatMeta } from "/app/pages/pressa/accreditation.vue?macro=true";
import { default as _91id_93jIOZWszUPyMeta } from "/app/pages/pressa/media/[id].vue?macro=true";
import { default as indexPs3dldu4HtMeta } from "/app/pages/pressa/media/index.vue?macro=true";
import { default as _91id_93CQQFTEyiglMeta } from "/app/pages/pressa/news/[id].vue?macro=true";
import { default as index1z0WRfIsaxMeta } from "/app/pages/pressa/news/index.vue?macro=true";
export default [
  {
    name: commissionHoTcyQTpjHMeta?.name ?? "about-commission",
    path: commissionHoTcyQTpjHMeta?.path ?? "/about/commission",
    children: [],
    meta: commissionHoTcyQTpjHMeta,
    alias: commissionHoTcyQTpjHMeta?.alias || [],
    redirect: commissionHoTcyQTpjHMeta?.redirect || undefined,
    component: () => import("/app/pages/about/commission.vue").then(m => m.default || m)
  },
  {
    name: ministry_45powersDNZsGFqa3JMeta?.name ?? "about-ministry-powers",
    path: ministry_45powersDNZsGFqa3JMeta?.path ?? "/about/ministry-powers",
    children: [],
    meta: ministry_45powersDNZsGFqa3JMeta,
    alias: ministry_45powersDNZsGFqa3JMeta?.alias || [],
    redirect: ministry_45powersDNZsGFqa3JMeta?.redirect || undefined,
    component: () => import("/app/pages/about/ministry-powers.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hm8OeeTuUiMeta?.name ?? "about-organizations-id",
    path: _91id_93hm8OeeTuUiMeta?.path ?? "/about/organizations/:id",
    children: [],
    meta: _91id_93hm8OeeTuUiMeta,
    alias: _91id_93hm8OeeTuUiMeta?.alias || [],
    redirect: _91id_93hm8OeeTuUiMeta?.redirect || undefined,
    component: () => import("/app/pages/about/organizations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXDBRioRxDcMeta?.name ?? "about-organizations",
    path: indexXDBRioRxDcMeta?.path ?? "/about/organizations",
    children: [],
    meta: indexXDBRioRxDcMeta,
    alias: indexXDBRioRxDcMeta?.alias || [],
    redirect: indexXDBRioRxDcMeta?.redirect || undefined,
    component: () => import("/app/pages/about/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: requisitesq0CXdNlnEyMeta?.name ?? "about-requisites",
    path: requisitesq0CXdNlnEyMeta?.path ?? "/about/requisites",
    children: [],
    meta: requisitesq0CXdNlnEyMeta,
    alias: requisitesq0CXdNlnEyMeta?.alias || [],
    redirect: requisitesq0CXdNlnEyMeta?.redirect || undefined,
    component: () => import("/app/pages/about/requisites.vue").then(m => m.default || m)
  },
  {
    name: _91id_9307jRmXJISLMeta?.name ?? "about-staff-id",
    path: _91id_9307jRmXJISLMeta?.path ?? "/about/staff/:id",
    children: [],
    meta: _91id_9307jRmXJISLMeta,
    alias: _91id_9307jRmXJISLMeta?.alias || [],
    redirect: _91id_9307jRmXJISLMeta?.redirect || undefined,
    component: () => import("/app/pages/about/staff/[id].vue").then(m => m.default || m)
  },
  {
    name: indexaV9c05q2UkMeta?.name ?? "about-staff",
    path: indexaV9c05q2UkMeta?.path ?? "/about/staff",
    children: [],
    meta: indexaV9c05q2UkMeta,
    alias: indexaV9c05q2UkMeta?.alias || [],
    redirect: indexaV9c05q2UkMeta?.redirect || undefined,
    component: () => import("/app/pages/about/staff/index.vue").then(m => m.default || m)
  },
  {
    name: claimJbNQuedtVVMeta?.name ?? "appeals-claim",
    path: claimJbNQuedtVVMeta?.path ?? "/appeals/claim",
    children: [],
    meta: claimJbNQuedtVVMeta,
    alias: claimJbNQuedtVVMeta?.alias || [],
    redirect: claimJbNQuedtVVMeta?.redirect || undefined,
    component: () => import("/app/pages/appeals/claim.vue").then(m => m.default || m)
  },
  {
    name: _91name_93FrnaUNS3NgMeta?.name ?? "books-name",
    path: _91name_93FrnaUNS3NgMeta?.path ?? "/books/:name",
    children: [],
    meta: _91name_93FrnaUNS3NgMeta,
    alias: _91name_93FrnaUNS3NgMeta?.alias || [],
    redirect: _91name_93FrnaUNS3NgMeta?.redirect || undefined,
    component: () => import("/app/pages/books/[name].vue").then(m => m.default || m)
  },
  {
    name: contactsHSHl3sOssUMeta?.name ?? "contacts",
    path: contactsHSHl3sOssUMeta?.path ?? "/contacts",
    children: [],
    meta: contactsHSHl3sOssUMeta,
    alias: contactsHSHl3sOssUMeta?.alias || [],
    redirect: contactsHSHl3sOssUMeta?.redirect || undefined,
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    children: [],
    meta: indexN6pT4Un8hYMeta,
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: accreditationJ7elZdgoatMeta?.name ?? "pressa-accreditation",
    path: accreditationJ7elZdgoatMeta?.path ?? "/pressa/accreditation",
    children: [],
    meta: accreditationJ7elZdgoatMeta,
    alias: accreditationJ7elZdgoatMeta?.alias || [],
    redirect: accreditationJ7elZdgoatMeta?.redirect || undefined,
    component: () => import("/app/pages/pressa/accreditation.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jIOZWszUPyMeta?.name ?? "pressa-media-id",
    path: _91id_93jIOZWszUPyMeta?.path ?? "/pressa/media/:id",
    children: [],
    meta: _91id_93jIOZWszUPyMeta,
    alias: _91id_93jIOZWszUPyMeta?.alias || [],
    redirect: _91id_93jIOZWszUPyMeta?.redirect || undefined,
    component: () => import("/app/pages/pressa/media/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPs3dldu4HtMeta?.name ?? "pressa-media",
    path: indexPs3dldu4HtMeta?.path ?? "/pressa/media",
    children: [],
    meta: indexPs3dldu4HtMeta,
    alias: indexPs3dldu4HtMeta?.alias || [],
    redirect: indexPs3dldu4HtMeta?.redirect || undefined,
    component: () => import("/app/pages/pressa/media/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CQQFTEyiglMeta?.name ?? "pressa-news-id",
    path: _91id_93CQQFTEyiglMeta?.path ?? "/pressa/news/:id",
    children: [],
    meta: _91id_93CQQFTEyiglMeta,
    alias: _91id_93CQQFTEyiglMeta?.alias || [],
    redirect: _91id_93CQQFTEyiglMeta?.redirect || undefined,
    component: () => import("/app/pages/pressa/news/[id].vue").then(m => m.default || m)
  },
  {
    name: index1z0WRfIsaxMeta?.name ?? "pressa-news",
    path: index1z0WRfIsaxMeta?.path ?? "/pressa/news",
    children: [],
    meta: index1z0WRfIsaxMeta,
    alias: index1z0WRfIsaxMeta?.alias || [],
    redirect: index1z0WRfIsaxMeta?.redirect || undefined,
    component: () => import("/app/pages/pressa/news/index.vue").then(m => m.default || m)
  }
]