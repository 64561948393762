export const appCdnURL = "https://storage.yandexcloud.net/minpros/public/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"Министерство просвещения"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"