import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import router_scrollBehavior_6se0L5aCQz from "/app/plugins/router.scrollBehavior.js";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  i18n_VfGcjrvSkj,
  router_scrollBehavior_6se0L5aCQz
]