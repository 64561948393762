import { defineNuxtPlugin, NuxtApp } from "#app";
import { createI18n } from "vue-i18n";

import ru from "../locales/ru";
import abh from "../locales/abh";

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "ru",
    fallbackLocale: "ru",
    availableLocales: ["ru", "abh"],
    messages: {
      ru,
      abh,
    },
  });

  nuxtApp.vueApp.use(i18n);
});
